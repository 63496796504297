.dropdown-hover:hover .dropdown-menu {
  display: block;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-nav {
  text-align: left;
}

.custom-toggle:focus,
.custom-toggle:active {
  border-color: white !important;
  box-shadow: none !important;
}

@media (max-width: 767.98px) {
  .navbar-nav {
    margin: 0;
    margin-top: 1rem;
    padding: 0;
    width: 100%;
  }

  .navbar-toggler-icon {
    filter: invert(1);
  }

  .navbar-nav .nav-item {
    width: 100%;
    text-align: left;
  }

  .navbar-nav .nav-link,
  .navbar-nav .dropdown-toggle,
  .navbar-nav .dropdown-item {
    text-align: left;
  }

  .navbar-nav .btn,
  .navbar-nav .language-toggle {
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;
  }

  .navbar-nav .dropdown-menu {
    width: 100%;
    text-align: left;
  }
}
