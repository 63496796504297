.carousel-container {
  justify-content: center;
  align-items: center;
}

.carousel-image-container {
  text-align: center;
}

.carousel-image {
  width: 100%;
  border-radius: 1em;
}

@media (max-width: 600px) {
  .margin-x-5 {
    margin-left: 10px;
    margin-right: 10px;
  }
}
