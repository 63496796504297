body {
  background-color: #232f3f;
}

div[class*="black-blue"] {
  background-color: #232f3f;
}

div[class*="dark-blue"] {
  background-color: #324358;
}

section[class*="black-blue"] {
  background-color: #232f3f;
}

nav[class*="dark-blue"] {
  background-color: #324358;
}

nav[class*="dark-blue"] a {
  color: #fff;
}

nav[class*="dark-blue"] a[class*="active"] {
  color: #fff !important;
}

footer[class*="dark-blue"] {
  background-color: #324358;
}

p[class*="text-gold"] {
  color: rgb(245, 199, 100);
}
