.toggle-switch {
  width: 50px;
  height: 25px;
  background-color: #ffffff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
}

.toggle-switch.checked {
  background-color: #ffffff;
}

.toggle-switch.disabled {
  background-color: #ffffff;
  cursor: not-allowed;
}

.toggle-switch .switch {
  width: 23px;
  height: 23px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.3s;
}

.toggle-switch.checked .switch {
  left: 26px;
}
